import { useDispatch, useSelector } from 'react-redux';
import { ListTable, TableFilters, TableMenuOpt } from '../../../uiComponents';
import { deleteGoal, getAllGoals } from '../../../../appRedux/actions/Goal';
import { formatPrice } from '../../../../util/formatPrice';
import { daysInMonth } from '../../../../util';
import moment from 'moment';
import { nameMonth } from '../../../../constants';
import { filters } from '../../../../constants/Goal.const';
import { mainBranch } from '../../../../constants/Branch.const';

export const GoalTable = () => {
  const dispatch = useDispatch();
  const {
    privileges: { goal: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const { branchId: loggedBranchId, branch: branchLogged } = useSelector(
    (state) => state.userAuth
  );

  const onDelete = (id) => {
    dispatch(deleteGoal(id));
  };

  const columns = [
    {
      title: 'Mes',
      sorter: true,
      dataIndex: 'month',
      key: 'month',
      render: (_, data) => {
        const date = moment(`${data.year}-${data.month}`, nameMonth);
        return date.format(nameMonth);
      },
    },
    {
      title: 'Meta',
      sorter: true,
      dataIndex: 'goal',
      key: 'goal',
      render: (goal) => `${formatPrice(goal)}`,
    },
    {
      title: 'Venta total',
      dataIndex: 'totalSale',
      key: 'totalSale',
      render: (totalSale) => `${formatPrice(totalSale || '0')}`,
    },
    {
      title: 'Falta para meta',
      dataIndex: 'totalSale',
      key: 'totalSale',
      render: (_, data) => `${formatPrice(data.goal - data.totalSale)}`,
    },
    {
      title: 'Meta diaria',
      dataIndex: 'month',
      key: 'month',
      render: (_, data) => {
        const days = daysInMonth(data.year, data.month);
        const dayGoal = data.goal / days;

        return `${formatPrice(dayGoal)}`;
      },
    },
    {
      title: 'Porcentaje de venta',
      dataIndex: 'totalSale',
      key: 'totalSale',
      render: (_, data) => {
        if (data.totalSale > 0) {
          const percentage = (data.totalSale / data.goal) * 100;
          return `${percentage.toFixed(2)}%`;
        }
        return '0%';
      },
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { goal_id: goalId }) => (
        <TableMenuOpt
          id={goalId}
          privileges={privileges}
          path={'metas'}
          action={{
            onDelete: {
              action: onDelete,
              params: [goalId],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        filters={filters}
        loggedBranchId={loggedBranchId}
        initialParams={
          branchLogged !== mainBranch ? { branchId: loggedBranchId } : undefined
        }
      />
      <ListTable
        columns={columns}
        name={'Metas'}
        getDataFunction={getAllGoals}
      />
    </>
  );
};
