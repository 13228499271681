import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import {
  getSubsidiaries,
  setSubsidiary,
  getCategories,
  getProviders,
  getCabainTypes,
  getAllRoles,
  getAllDepartments,
  getAllJobPositions,
  getTreatmentTypes,
  getCabains,
} from '../../../appRedux/actions';
import { CustomSelect } from '../CustomSelect';
import {
  payConditionsConst,
  payMethods,
  payStatus,
  roleConst,
  orderTypes,
  productTypes,
  productStatus,
  buttonLabel,
  formConfig,
  orderStatus,
  placeHolders,
  genders,
  maritalStatus,
  statusDescriptions,
  types,
  customerConst,
  couponTypeConst,
} from '../../../constants';
import {
  dateFormat,
  monthAndYear,
  yearAndMonth,
} from '../../../constants/TimeConst';
import moment from 'moment';
import { categoryTypeConst } from '../../../constants/Category.const';
import { useRef } from 'react';
import { getModules } from '../../../appRedux/actions/Module';
import { actionsConst } from '../../../constants/Logs.const';
import { dateStatus } from '../../../constants/Diary.const';
import { paymentMethod } from '../../../constants/Sales';
import { setFiltersData } from '../../../appRedux/actions/General';
import { useLocation } from 'react-router-dom';
import { FilterConst } from '../../../constants/Filters.const';

export const TableFilters = ({
  privileges = [],
  filters,
  searchFilters,
  searchInputInitialValues,
  role,
  params = {},
  blackListParam = false,
  initialParams = {},
}) => {
  const dispatch = useDispatch();
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { categories } = useSelector((state) => state.category);
  const { providers } = useSelector((state) => state.provider);
  const { cabainTypes } = useSelector((state) => state.cabainType);
  const { cabains } = useSelector((state) => state.cabain);
  const { roles } = useSelector((state) => state.role);
  const { departments } = useSelector((state) => state.departments);
  const { jobPositions } = useSelector((state) => state.jobPositions);
  const { modules } = useSelector((state) => state.modules);
  const [form] = Form.useForm();
  const [branchId, setBranchId] = useState('');
  const { treatmentTypes } = useSelector((state) => state.treatmentType);
  const { RangePicker } = DatePicker;
  const extraParams = useRef(params);
  const { pathname } = useLocation();

  useEffect(() => {
    const blackList = blackListParam && '0';
    let params = {
      status: 1,
      blackList,
      extraParams: extraParams.current,
      withDeleted: 'false',
      dates: FilterConst.DATE_RANGE_SECTIONS.includes(pathname)
        ? `${moment().startOf('month').format(dateFormat)},${moment().format(
            dateFormat
          )}`
        : null,
      monthsAndYears: FilterConst.DATE_RANGE_SECTIONS.includes(pathname)
        ? `${moment().format(yearAndMonth)},${moment().format(yearAndMonth)}`
        : null,
    };
    if (initialParams.branchId) {
      params = { ...params, ...{ branchesIds: [initialParams.branchId] } };
    }
    dispatch(setFiltersData(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, pathname]);

  useEffect(() => {
    if (filters.branches || filters.transferBranches) {
      dispatch(getSubsidiaries({ status: 1 }));
    }
  }, [dispatch, filters.branches, filters.transferBranches]);

  useEffect(() => {
    if (filters.categories) {
      let categoryType = '';
      if (filters.treatmentCategory) {
        categoryType = 'treatment';
      } else if (filters.productsCategories) {
        categoryType = 'product';
      }
      dispatch(getCategories({ type: categoryType }));
    }
  }, [
    dispatch,
    filters.categories,
    filters.treatmentCategory,
    filters.productsCategories,
  ]);

  useEffect(() => {
    if (filters.cabainTypes) dispatch(getCabainTypes());
  }, [dispatch, filters.cabainTypes]);

  useEffect(() => {
    if (filters.cabains) dispatch(getCabains());
  }, [dispatch, filters.cabains]);

  useEffect(() => {
    if (filters.providers) dispatch(getProviders());
  }, [dispatch, filters.providers]);

  useEffect(() => {
    if (filters.treatmentType) dispatch(getTreatmentTypes());
  }, [dispatch, filters.treatmentType]);

  useEffect(() => {
    if (filters.modules) dispatch(getModules());
  }, [dispatch, filters.modules]);

  useEffect(() => {
    dispatch(setSubsidiary(branchId));
  }, [dispatch, branchId]);

  useEffect(() => {
    if (filters.roles) dispatch(getAllRoles());
  }, [dispatch, filters.roles]);

  useEffect(() => {
    if (filters.departments) dispatch(getAllDepartments());
  }, [dispatch, filters.departments]);

  useEffect(() => {
    if (filters.jobPositions) dispatch(getAllJobPositions());
  }, [dispatch, filters.jobPositions]);

  useEffect(() => {
    if (initialParams.branchId)
      form.setFieldsValue({ branchesIds: [initialParams.branchId] });
  }, [initialParams.branchId, form]);

  const onBranchChange = (branchId) => {
    setBranchId(branchId);
  };

  const onSubmit = (values) => {
    if (values.dates)
      values.dates = values.dates.map((date) =>
        moment(date)?.format(dateFormat)
      );

    if (values.monthsAndYears)
      values.monthsAndYears = values.monthsAndYears.map((date) =>
        moment(date)?.format(yearAndMonth)
      );
    dispatch(setFiltersData(values));
  };

  return (
    <Row justify="end">
      <Form
        {...formConfig}
        onFinish={onSubmit}
        form={form}
        initialValues={searchInputInitialValues}
      >
        <Space>
          {filters.dates && (
            <Form.Item name="dates">
              <RangePicker format={dateFormat} />
            </Form.Item>
          )}

          {filters.monthsAndYears && (
            <Form.Item name="monthsAndYears">
              <RangePicker picker="month" format={monthAndYear} />
            </Form.Item>
          )}

          {filters.categories && (
            <Form.Item name="categoriesIds">
              <CustomSelect
                mode="multiple"
                placeholder={'Categorías'}
                style={{ width: '150px' }}
                options={categories?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.branches && (
            <Form.Item
              name="branchesIds"
              hidden={!privileges?.includes('approve')}
            >
              <CustomSelect
                mode="multiple"
                placeholder={placeHolders.branch}
                style={{ minWidth: '200px' }}
                onChange={onBranchChange}
                options={subsidiaries?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.jobPositions && (
            <Form.Item name="jobPositionId">
              <CustomSelect
                placeholder="Puesto"
                style={{ width: '150px' }}
                options={jobPositions?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}
          {filters.departments && (
            <Form.Item name="departmentId">
              <CustomSelect
                placeholder="Departamento"
                style={{ width: '150px' }}
                options={departments?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}
          {filters.roles && (
            <Form.Item name="roleId">
              <CustomSelect
                placeholder="Rol"
                style={{ width: '150px' }}
                options={roles?.map(({ id, role }) => ({
                  value: id,
                  label: role,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.transferBranches && (
            <>
              <Form.Item
                name="originBranchId"
                hidden={
                  !privileges?.includes('approve') &&
                  !privileges?.includes('reports')
                }
              >
                <CustomSelect
                  hidden={role === roleConst.branchManager}
                  placeholder={placeHolders.originBranch}
                  style={{ minWidth: '150px' }}
                  onChange={onBranchChange}
                  options={subsidiaries?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                ></CustomSelect>
              </Form.Item>

              <Form.Item
                name="destinyBranchId"
                hidden={
                  !privileges.includes('approve') &&
                  !privileges.includes('reports')
                }
              >
                <CustomSelect
                  placeholder={placeHolders.destinyBranch}
                  style={{ minWidth: '150px' }}
                  onChange={onBranchChange}
                  options={subsidiaries?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                ></CustomSelect>
              </Form.Item>
            </>
          )}

          {filters.applianceType && (
            <Form.Item name="applianceType">
              <CustomSelect
                placeholder="Tipo de aparato"
                style={{ width: '130px' }}
                options={types}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.treatmentType && (
            <Form.Item name="treatmentTypeId">
              <CustomSelect
                placeholder="Tipo de tratamiento"
                style={{ width: '150px' }}
                options={treatmentTypes?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.couponType && (
            <Form.Item name="couponType">
              <CustomSelect
                placeholder="Tipo de cupón"
                style={{ width: '150px' }}
                options={couponTypeConst?.map(({ value, label }) => ({
                  value,
                  label,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.modules && (
            <Form.Item name="moduleId">
              <CustomSelect
                placeholder="Módulos"
                style={{ width: '150px' }}
                options={modules?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.actions && (
            <Form.Item name="action">
              <CustomSelect
                placeholder="Acción"
                style={{ width: '150px' }}
                options={actionsConst}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.franchise && (
            <Form.Item name="franchise">
              <CustomSelect
                placeholder="¿Es franquicia?"
                style={{ width: '150px' }}
                options={[
                  { value: '1', label: 'Si' },
                  { value: '0', label: 'No' },
                ]}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.productTypes && (
            <Form.Item name="productType">
              <CustomSelect
                placeholder="Tipo"
                style={{ width: '150px' }}
                options={productTypes}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.cabainTypes && (
            <Form.Item name="cabainType">
              <CustomSelect
                placeholder="Tipo de cabina"
                style={{ width: '150px' }}
                options={cabainTypes?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.cabains && (
            <Form.Item name="cabainId">
              <CustomSelect
                placeholder="Cabina"
                style={{ width: '150px' }}
                options={cabains?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.gender && (
            <Form.Item name="gender">
              <CustomSelect
                placeholder="Género"
                style={{ width: '130px' }}
                options={genders}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.maritalStatus && (
            <Form.Item name="maritalStatus">
              <CustomSelect
                placeholder="Estado civil"
                style={{ width: '130px' }}
                options={maritalStatus}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.status && (
            <Form.Item name="status">
              <Select
                allowClear
                placeholder="Status"
                style={{ width: '100px' }}
                options={[
                  { label: 'Activo', value: '1' },
                  { label: 'Inactivo', value: '0' },
                ]}
              ></Select>
            </Form.Item>
          )}

          {filters.appointmentStatus && (
            <Form.Item name="appointmentStatus">
              <CustomSelect
                placeholder="Status Cita"
                style={{ width: '100px' }}
                options={dateStatus}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.blackList && (
            <Form.Item name="blackList">
              <CustomSelect
                placeholder="Lista negra"
                style={{ width: '120px' }}
                options={[
                  customerConst.blackList,
                  customerConst.regularCustomer,
                ]}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.productStatus && (
            <Form.Item name="productStatus">
              <CustomSelect
                placeholder="Estado"
                style={{ width: '150px' }}
                options={productStatus}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.applianceStatus && (
            <Form.Item name="applianceStatus">
              <CustomSelect
                placeholder="Estado"
                style={{ width: '130px' }}
                options={statusDescriptions}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.orderType && (
            <Form.Item name="orderType">
              <CustomSelect
                placeholder="Tipo"
                style={{ width: '200px' }}
                options={orderTypes}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.orderStatus && (
            <Form.Item name="orderStatus">
              <CustomSelect
                placeholder="Status"
                style={{ width: '100px' }}
                options={orderStatus}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.providers && (
            <Form.Item name="providersIds">
              <CustomSelect
                mode="multiple"
                placeholder={'Proveedores'}
                style={{ width: '150px' }}
                options={providers?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.paymentMethod && (
            <Form.Item name="paymentMethod">
              <CustomSelect
                placeholder={'Método de pago'}
                style={{ width: '150px' }}
                options={paymentMethod}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.payMethods && (
            <Form.Item name="payMethod">
              <CustomSelect
                placeholder={'Método de pago'}
                style={{ width: '150px' }}
                options={payMethods}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.payStatus && (
            <Form.Item name="payStatus">
              <CustomSelect
                placeholder={'Estado de pago'}
                style={{ width: '150px' }}
                options={payStatus}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.payConditions && (
            <Form.Item name="payCondition">
              <CustomSelect
                multiple
                placeholder={'Condiciones de pago'}
                style={{ width: '150px' }}
                options={payConditionsConst}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.categoryType && (
            <Form.Item name="type">
              <CustomSelect
                multiple
                placeholder={'Tipo'}
                style={{ width: '150px' }}
                options={categoryTypeConst}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.search && (
            <Form.Item
              hidden={[roleConst.medic, roleConst.cosmetologist].includes[role]}
            >
              <Input.Group compact>
                <Form.Item name={['search', 'filter']} noStyle>
                  <CustomSelect
                    placeholder="Selecciona una opción"
                    style={{ minWidth: '150px' }}
                    options={searchFilters.map(({ value, label }) => ({
                      value,
                      label,
                    }))}
                  ></CustomSelect>
                </Form.Item>

                <Form.Item name={['search', 'value']} noStyle>
                  <Input
                    style={{ width: '50%' }}
                    placeholder="Buscar"
                    allowClear
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" className="btn-form" htmlType="submit">
              {buttonLabel.search}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Row>
  );
};
