import moment from 'moment';

export const cardConfig = {
  width: '100%',
  margin: '0 auto',
  maxWidth: 1200,
};

export const formConfig = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const oneColumnFormConfig = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
  },
};

export const twoColumnsFormConfig = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 },
  },
};

export const threeColumnsFormConfig = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 15 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 9 },
  },
};

export const couponsFormConfig = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 9 },
  },
};

export const productStockColumnsFormConfig = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 18 },
  },
};

export const transferTwoColumnsFormConfig = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 20 },
  },
};

export const twoColumnsFormConfigAssortment = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 15 },
  },
};

export const stockColumnFormConfig = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 17 },
  },
};

export const sessionColumnFormConfig = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
};

export const treatmentPackageFormConfig = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 },
  },
};

export const messages = {
  validation: {
    sku: 'El SKU debe de tener una longitud entre 4 y 12 carácteres',
    pin: 'El pin debe de ser de 4 carácteres',
    max: 'El valor máximo no puede ser menor que el mínimo',
    min: 'El valor mínimo no puede ser mayor que el máximo',
    rfc: 'RFC Inválido',
    curp: 'CURP Inválido',
    image: 'Se necesita una imágen',
    password:
      'La contraseña debe contener almenos 1 mayúscula, 1 minúscula, y 1 número',
  },
  required: (label) => `El campo ${label} es requerido`,
  imageSize: 'La imagen debe ser menor a 1 Mb',
  lenght: 'Longitud inválida',
};

export const modal = {
  title: '¿Deseas confirmar esta orden?',
  body: 'Al dar click en aceptar se estara generando la orden',
  okButton: 'Aceptar',
  cancelButton: 'Cancelar',
};

export const buttonLabel = {
  save: 'Guardar',
  remember: 'Recordar',
  forgetPassword: '¿Olvido su Contraseña?',
  login: 'Iniciar Sesión',
  keepSession: 'Continuar Sesión',
  addProduct: 'Agregar Producto',
  approve: 'Aprobar',
  approvePending: 'Pendiente por Aprobar',
  reject: 'Rechazar',
  rejected: 'Rechazado',
  next: 'Siguiente',
  previous: 'Atras',
  search: 'Buscar',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  lock: 'Bloquear',
  resetPassword: 'Resetear Contraseña',
  changePassword: 'Cambiar Contraseña',
  resendCode: 'Reenviar',
  resetPin: 'Resetear PIN',
  changePin: 'Cambiar PIN',
  checkin: 'Registrar entrada',
  export: 'Exportar',
  exportPDF: 'PDF',
  exportCSV: 'CSV',
  download: 'Descargar`',
  consent: 'Consentimiento',
  refresh: 'Actualizar',
  filter: 'Filtrar',
};

export const label = {
  activeText: 'Activo',
  inactiveText: 'Inactivo',
};

export const placeHolders = {
  cosmetologist: 'Selecciona cosmetologa',
  branchCode: 'Código de Sucursal',
  status: 'Selecciona el status de la cita',
  assign: 'Selecciona quien atendera la cita',
  commissionAgent: 'Selecciona un comisionista',
  tutor: 'Selecciona un tutor',
  consent: 'Selecciona un consentimiento',
  cabain: 'Selecciona una cabina',
  branch: 'Selecciona sucursal',
  originBranch: 'Sucursal origen',
  destinyBranch: 'Sucursal destino ',
  type: 'Selecciona un tipo',
  provider: 'Selecciona un proveedor',
  email: 'ejemplo@moldearte.com',
  password: 'Contraseña',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva Contraseña',
  iva: 'Selecciona el IVA',
  contentType: 'Selecciona el tipo de contenido',
  measureUnit: 'Selecciona la unidad de medida',
  franchiseTax: 'Selecciona el Impuesto de Franquicia',
  payConditions: 'Selecciona las condiciones de pago',
  payMethod: 'Selecciona la forma de Pago',
  payState: 'Selecciona el estado del pago',
  pin: 'PIN',
  newPin: 'Nuevo PIN',
  confirmPin: 'Confirmar PIN',
  treatmentType: 'Seleccione un tipo de tratamiento',
  aplication: 'Selecciona las zonas de aplicacion',
  appliances: 'Selecciona los aparatos a utilizar',
  product: 'Selecciona un producto',
  date: 'Selecciona una fecha',
  default: 'Selecciona...',
  jobPosition: 'Selecciona un puesto',
  department: 'Selecciona un departamento',
  providers: 'Selecciona uno o más proveedores',
  categories: 'Selecciona una o más categorías',
  regime: 'Selecciona el régimen fiscal',
  productType: 'Selecciona el tipo de producto',
  role: 'Selecciona un rol',
  workShift: 'Selecciona un turno',
  gender: 'Selecciona un género',
  maritalStatus: 'Selecciona un estado civil',
  educationalDegree: 'Selecciona un nivel educativo',
  bloodType: 'Selecciona un tipo de sangre',
  diseases: 'Diabétes, Asma, Artritis... o N/A (Si no aplica)',
  allergies: 'Polen, Maní, Mariscos... o N/A (Si no aplica)',
  drugAllergies:
    'Penicilina, Ibuprofeno, Naproxeno Sódico ... o N/A (Si no aplica)',
  category: 'Selecciona una categoría',
  reason: 'Selecciona un motivo',
  lockType: 'Selecciona tipo de bloqueo',
  noteType: 'Selecciona un tipo de nota',
  code: 'Código',
  confimPassword: 'Confirmar contraseña',
  appointmentType: 'Selecciona tipo de cita',
  appointmentMethod: 'Selecciona un método de cita',
  patient: 'Selecciona un paciente',
  paymentMethods: 'Selecciona un método de pago',
  agendaOptions: 'Selecciona una opción',
  promo: 'Selecciona una promoción',
  package: 'Selecciona un paquete',
  appliance: 'Selecciona aparato',
  purchasedProducts: 'Tratamientos adquiridos',
  cancellationReason: 'Motivo de cancelación',
  treatment: 'Selecciona un tratamiento',
  areas: 'Selecciona area(s)',
  refundMethod: 'Selecciona un método de devolución',
  medic: 'Selecciona un medico',
  profitability: 'Selecciona una opción',
  confirmAppointment: 'Confirmar cita',
  days: 'Seleccione los días a bloquear',
};

export const masks = {
  phone: '(000)000-0000',
  percent: '0[0][0]%',
  numPercent: '0[0][0].[0][0]%',
};

export const empytMaskValue = {
  phone: '(___)___-____',
  percent: '%',
};

export const lengthValidation = (label, chars) => {
  return `${label} debe de ser de ${chars} carácteres máximo`;
};

export const minLengthValidation = (label, chars) => {
  return `${label} debe de ser de ${chars} carácteres mínimo`;
};

export const dateFormat = 'YYYY-MM-DD';

export const dateFormatMx = 'DD-MM-YYYY';

export const disableDaysAfterToday = (current) => {
  return current > 0 && current > moment().endOf('day');
};

export const orderStatus = [
  { value: 'En proceso', label: 'En proceso' },
  { value: 'En transito', label: 'En tránsito' },
  { value: 'Terminada', label: 'Terminada' },
  { value: 'Rechazada', label: 'Rechazada' },
  { value: 'Eliminada', label: 'Eliminada' },
];

export const paymentMethods = [
  { value: 'Efectivo', label: 'Efectivo' },
  { value: 'Paypal', label: 'Paypal' },
  { value: 'Puntos', label: 'Puntos' },
  { value: 'Tarjeta de crédito/débito', label: 'Tarjeta de crédito/débito' },
];

export const weekdaysConst = [
  { monday: 'Lunes' },
  { tuesday: 'Martes' },
  { wednesday: 'Miércoles' },
  { thursday: 'Jueves' },
  { friday: 'Viernes' },
  { saturday: 'Sabado' },
  { sunday: 'Domingo' },
];

export const weekDaysOrder = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo',
];

export const initialOpenState = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

export const daysDictionary = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};
